<template>
  <div>
    <el-button
      type="primary"
      @click="addMenu"
      style="margin-top: 20px; margin-left: 20px;margin-bottom: 20px;"
      v-if="data.authMenus.add"
      ><el-icon><Plus /></el-icon>
      新增
    </el-button>
  </div>
  <vxe-table
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
    round
    show-overflow
    height="550"
    align="center"
    ref="xTable"
    :row-config="{ keyField: 'id', height: 40 }"
    :tree-config="{ transform: true, reserve: true }"
    :scroll-y="{ gt: 20 }"
    :data="data.tableData"
    header-cell-class-name="header"
    style="width: 96%; margin-left: 2%;margin-bottom: 20px;"
  >
    <vxe-column field="name" title="名称" tree-node></vxe-column>
    <vxe-column field="type" title="类型">
      <template #default="{ row }">
        <el-tag class="ml-2" v-if="row.type === 'MODULE'">平台</el-tag>
        <el-tag class="ml-2" v-else-if="row.type === 'MENU'" type="success">菜单</el-tag>
        <el-tag class="ml-2" v-else-if="row.type === 'PAGE'" type="success">页面</el-tag>
        <el-tag class="ml-2" v-else type="info">按钮</el-tag>
      </template>
    </vxe-column>
    <vxe-column field="sort" title="排序"></vxe-column>
    <vxe-column title="操作">
      <template #default="scope">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.delete">删除</el-button>
      </template>
    </vxe-column>
  </vxe-table>
  <AddMenu
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :menuMsg="data.menuMsg"
    :menus="data.menus"
    @update-dialogVisible="updateDialogVisible"
  ></AddMenu>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { reactive, onMounted, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import AddMenu from './AddMenu.vue'
import API from '../../../service/api'
import { useRoute } from 'vue-router'
const props = defineProps({
  auths: {},
})
const data = reactive({
  tableData: [],
  dialogVisible: false,
  menuMsg: {
    menuType: 'P',
  },
  treeProps: {
    children: 'childrens',
  },
  authMenus: {},
  menus: [],
  loading: false,
})
const route = useRoute()
const xTable = ref(null)
onMounted(() => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  searchLists()
  searchTreeMenu()
})

const operationData = (data = []) => {
  data.forEach(item => {
    if (item.children.length !== 0) {
      operationData(item.children)
    } else {
      item.children = null
    }
  })
}

const searchLists = () => {
  if (data.authMenus.query) {
    data.loading = true
    API.getResourceAllList(null).then(res => {
      if (res.code === '200') {
        data.tableData = res.data.map(a => ({
          ...a,
          id: a.code,
          parentId: a.parentCode,
        }))
        data.loading = false
      } else {
        ElMessage.error(res.msg)
        data.loading = false
      }
    })
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
const searchTreeMenu = () => {
  API.getResourceAllTree(null).then(res => {
    if (res.code === '200') {
      operationData(res.data)
      data.menus = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//添加
const addMenu = () => {
  data.menuMsg = {
    menuName: '',
    name: '',
    path: '',
    component: '',
    sort: 0,
    type: 'MODULE',
    icon: 'Tools',
    isHide: 0,
    isLink: '',
    isKeepAlive: 1, // 是否缓存路由数据[0是，1否]
    isFull: 1, // 是否缓存全屏[0是，1否]
    isAffix: 1, // 是否缓存固定路由[0是，1否]
  }
  data.dialogVisible = true
}
const editRow = rowMsg => {
  let content = JSON.parse(rowMsg.content || '{}')
  data.menuMsg = {
    ...rowMsg,
    ...content,
    menuName: rowMsg.name,
    name: content.menuName,
  }
  data.dialogVisible = true
}
const deleteRow = rowMsg => {
  ElMessageBox.confirm('确认要删除当前配置吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    API.resourceDelete({
      type: 'RESOURCE',
      value: rowMsg.code,
    }).then(res => {
      if (res.code === '200') {
        ElMessage.success('删除成功')
        searchLists()
      } else {
        ElMessage.error(res.msg)
      }
    })
  })
}

//操作数据
const showDetail = (index, rowMsg) => {
  switch (index) {
    case 1:
      editRow(rowMsg)
      break
    case 2:
      deleteRow(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    searchLists()
    searchTreeMenu()
  }
}
</script>

<style></style>
